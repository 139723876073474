/* Define the custom font */
@font-face {
  font-family: "VT323";
  src: url("../Assets/Fonts/VT323-Regular.ttf") format("opentype"); /* Ensure path and format are correct */
}
@font-face {
  font-family: "PPNeueBit-Bold";
  src: url("../Assets/Fonts/PPNeueBit-Bold.otf") format("opentype"); /* Ensure path and format are correct */
}
@font-face {
  font-family: "PPMondwest";
  src: url("../Assets/Fonts/PPMondwest-Regular.otf") format("opentype"); /* Ensure path and format are correct */
}

h2 {
  font-size: calc(10px + 8vmin);
  font-family: 'PPMondwest';
}

p, ol {
  line-height: 140%;
  font-size: calc(12px + 1vmin); /* Responsive font size */
  /* font-family: 'Space Grotesk'; */
}

.badlib {
  font-size: calc(14px + 1.5vmin);
}

h3 {
  font-size: calc(10px + 3vmin); /* Responsive font size */
}

h1 {
  font-size: calc(10px + 19vmin); /* Responsive font size */
  line-height: 58%;
  margin: 0; /* Remove default margin */
  font-family: "PPNeueBit-Bold", sans-serif; 
}

.default button {
  font-size: calc(12px + 1vmin);
  font-family: "PPNeueBit-Bold";
}

.main button {
  font-size: calc(14px + 3.2vmin);
  font-family: "PPNeueBit-Bold";
}
.popup-nav a {
  font-size: calc(14px + 3.2vmin);
  font-family: "VT323";
}
.menu {
  font-size: calc(10px + 2vmin);
  font-family: "VT323";
}

.mark {
  font-size: calc(10px + 1.5vmin);
  font-family: "VT323";
}

 
@media (max-width: 768px) {
  
  h1 {
    font-size: calc(10px + 15vmin); /* Responsive font size */
    line-height: 70%;

  }
}

