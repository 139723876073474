
.development-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  grid-template-rows: auto; /* Rows will be automatically sized */
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  grid-gap: 20px; /* Optional: add some space between grid items */
}

.character-carousel {
  grid-column: 1 / 3; /* Span from column 1 to column 3 (effectively spanning both columns) */
  grid-row: 1; /* Place it in the first row */
}

.character-carousel:nth-child(2) {
  grid-column: 1 / 3; /* Span from column 1 to column 3 (effectively spanning both columns) */
  grid-row: 2; /* Place it in the second row */
}


.first {

  grid-row: 3; /* Place it in the first row */
}

@media (max-width: 768px) {
  .development-container {
    grid-template-columns: 1fr;
  
  }

  .character-carousel {
    grid-column: 1 / 2; /* Span from column 1 to column 3 (effectively spanning both columns) */
    grid-row: 1; /* Place it in the first row */
  }
  
  .character-carousel:nth-child(2) {
    grid-column: 1 / 2; /* Span from column 1 to column 3 (effectively spanning both columns) */
    grid-row: 2; /* Place it in the second row */
  }
}


