/* src/Components/ListItems.css */
.list-items-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between boxes */
}

.list-item-box {
  /* background-color: #030303; */
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.list-item-box p {
  margin: 0;
  margin-bottom: 5px;
}

.list-item-box a {
  color: rgb(109, 71, 0);
  text-decoration: none;
}

.list-item-box a:hover {
  color: orange;

}
