.slideshow-container {
  position: relative;
  display: flex;
  flex-direction: column; /* Ensures thumbnails are below the main image */
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%; /* Full width of the parent container */
  height: auto; /* Height will adjust based on the content */
  margin: 20px auto;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
}

.slideshow-image {
  width: 90%; /* Make the image fill the width of the container */
  height: auto; /* Height adjusts automatically based on aspect ratio */
  object-fit: contain;
  transition: opacity 0.5s ease-in-out;
}

.arrow {
  position: absolute;
  width: 6%;
  cursor: pointer;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
}

.thumbnails-container {
  display: flex;
  flex-wrap: wrap; /* Allows thumbnails to wrap if needed */
  justify-content: center;
  gap: 10px;
  padding-top: 10px; /* Space between the main image and thumbnails */
  width: 100%; /* Ensure the container takes full width */
}

.thumbnail {
  width: calc(100% / 8 - 10px); /* Adjust thumbnail width based on number of images */
  height: auto; /* Maintain aspect ratio */
  max-height: 60px; /* Maximum height to keep thumbnails consistent */
  object-fit: cover;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease, transform 0.3s ease;
  border: 2px solid transparent;
}

.thumbnail.active {
  opacity: 1;
  border: 2px solid #333;
}

.thumbnail:hover {
  opacity: 1;
  transform: scale(1.1);
}

/* Fade-in and fade-out animations */
.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
@media (max-width: 768px) {

   .slideshow-image {
    width: 100%; 

  }
}
