.App {
  display: flex;
  flex-direction: column;
  padding: 0 10vw;
  margin:0;
  box-sizing: border-box;
}

body {
  margin:0;
}
/* General button styles */
 button { 
  background-color: #000;
  border: 1px solid #000;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin: 2vh  0;
  padding: 1.5vh 2vw;
  text-align: center;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  text-transform: uppercase;
  height:fit-content;

}

button :focus {
  text-decoration: none;
}

button:hover {
background-color: rgb(165, 165, 165);
}

button :active {
  box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
}



a {
  text-decoration: none;
  color: inherit;
}

/* Button-top specific styles */
.button-top {
  position: fixed;
  bottom: 10%;
  right: 6vw;
  z-index: 10;
}
.button-top > button {
  border: none;
  box-shadow: none; /* Remove any box shadow if applicable */
  padding: 0;
  background: none; /* Ensure no background color is applied */
}

.hide {
  display: none;
}

.block {
  width: 100%;
  margin-top: 10vh;
  display: grid;
}

.block > :first-child{
  margin-top: 0;

}
#team h3 {
  font-family: 'VT323';
  font-size: 50px;
}
.badlib {
  font-size: 25px;
  padding: 2%;
}
.badlib a {
  color: orange;
}

.block:first-of-type {
  margin-bottom: 20vh;
}

.block:last-of-type {
  margin-bottom: 20vh;
}

.highlight {
  border-top: 1px grey solid;
  border-bottom: 1px grey solid;
}

.highlight p {
  line-height: 50%;
}

.about-container {
  display: flex;
  flex-direction: column;
  gap: 5vw;
  margin: 0;
}

.about-container > :first-child {
 columns: 2;
 width: 80%;
 align-self: center;
}

.landing-container {
  display: grid;
  grid-template-columns: 1.2fr 1.5fr 0.8fr;
  grid-template-rows: auto 1fr;
  row-gap: 5vh;
}

.landing-container > :first-child {
  grid-column: 1 / 4;
  grid-row: 1;
}

.landing-container > :nth-child(2) {
  grid-column: 2 / 3;
  grid-row: 2;
}
.landing-pic-mobile {
  display:none;
}

.about-cont-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2vw;
}

.disc-mob {
  display: none;
}

@media (max-width: 768px) {
  
  .landing-container > :nth-child(2) {
    grid-column: 1 / 4;
    grid-row: 2;
  }

  .plant, .comp {
    display: none;
  }

  .landing-pic-mobile {
    display:block;
  }

  .about-container > :first-child {
    columns: 1;
    width: 100%;
    align-self: center;
   }

   .about-cont-2 {
    grid-template-columns: 1fr;
    gap: 5vw;
  }
  .disc-mob {
    display: inline-block;

  }

  .disc-desk {
    display: none;

  }
}


.plant {
  position: absolute;
  width: 15%;
  z-index: -1;
  right: 7vw;
  top: 32vh;
  transform: rotate(15deg);
}

.comp {
  position: absolute;
  width: 28%;
  z-index: -1;
  left: 8vw;
  top: 62vh;
  transform: rotate(-10deg);
}

.contact-container {
  background-color: rgb(191, 191, 255);
  border-radius: 15px;
  padding: 3vw;
  width: 70%;
  justify-self: center;
}

.form-container {
  margin-top: 2vh; 
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vh; 
}

.contact-form input[type="email"] {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.socials img {
  width: 50px;
}
.socials {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.footer {
  width: 100%;
  margin: 0;

}

.dracul {
  width: 70%;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 6vw;
    width: 100%;
  
  }
}




