.App-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.App-header a {
  text-transform: uppercase;
  padding: 2.5vw 2vw;
  text-align: center;
}

.menu {
  background-color: #ffffff;
  border: 1px solid black;
  border-top: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
  align-items: center;
  justify-items: stretch;
  border-radius: 0 0 20px 20px;
  height: max-content;
  overflow: hidden;
}

.menu a:hover, .menu .standout {
  background-color: rgb(202, 202, 202);
}

.menu .standout:hover {
  background-color: rgb(156, 156, 156);
}

.menu > *:not(:last-child) {
  border-right: 1px solid black;
}

.mark {
  display: flex;
  flex-direction: column; /* Stack the text and image vertically */
  align-items: center; /* Center the contents horizontally */
  justify-content: space-between; /* Distribute space between elements */
  background-color: rgb(138, 20, 20);
  width: 150px;
  color: white;

}

.mark:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

.mark a {
  text-align: center; /* Center the text */
  flex-grow: 1; /* Allow the text to take up the remaining space */
}

.mark-image {
  width: 100%; /* Adjust the width as needed */
  max-width: 150px; /* Set a maximum width to control the size */
  height: 20px; /* Maintain the aspect ratio */
  margin-top: auto; /* Push the image to the bottom */
  padding-top: 10px; /* Add some space between the text and image */
}


/* Style the hamburger icon */
.mobile-menu-icon {
  display: none; /* Hide by default */
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  cursor: pointer;
  z-index: 1001; /* Ensure it appears above other elements */
}

@media (max-width: 768px) {
  .mobile-menu-icon {
    display: block; /* Show on mobile screens */
  }
  .menu, .mark {
    display: none; /* Hide the menu and mark on mobile */
  }
}

/* Fullscreen popup menu */
.popup-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9); /* Dark background */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  z-index: 1000; /* Ensure it covers the entire screen */
}

/* Popup menu links */
.popup-nav a {
  color: rgb(0, 0, 0);
  font-size: calc(14px + 4vmin);
  margin: 15px 0;
  text-decoration: none;

  text-align: center;
  padding: 10px;
  width: 100%;
}

.popup-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-nav a:hover {
  background-color: rgba(209, 209, 209, 0.1);
}

/* Close icon */
.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 32px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  z-index: 1002;
}

